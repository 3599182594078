import { useState } from 'react';
import { GameStats } from '../../lib/localStorage';
import { BaseModal } from '../modals/BaseModal';
import { Progress } from './Progress';

type Props = {
  gameStats?: GameStats;
  isGameWon?: boolean;
  paramValue?: any;
  headerTitle?: any;
  signupModuleToggler?: any;
  signupModelDescription?: any;
  setIframeModule? : any;
};

export const Histogram = ({
  gameStats,
  isGameWon = false,
  paramValue,
  headerTitle,
  signupModuleToggler,
  signupModelDescription,
  setIframeModule,

}: Props) => {
  const winDistribution = gameStats?.winDistribution;
  const [iframe, setIframe] = useState(false);
  const maxValue = winDistribution && Array.isArray(winDistribution) ? Math.max(...winDistribution) : 0;
  // alert(maxValue);
  if (!winDistribution || !Array.isArray(winDistribution)) {
    return <div>No data available</div>;
  }
  return (
    <>
      <div className={`min_height flex pp-two text-sm dark:text-white backGroundColor ${paramValue === 'true' || paramValue == 'true' ? 'is-embed' : ''}`} style={{ marginBottom: '25px' }}>
        {winDistribution.map((value, i) => (

          <Progress
            key={i}
            index={i}
            size={90 * (value / maxValue)}
            label={String(value)}
            isGameWon={isGameWon}
          />
        ))}
      </div>
      {
        signupModuleToggler && (
          <div className={paramValue === 'true' || paramValue == 'true' ? 'is-embed' : ''} style={{ marginBottom: '20px' }}>
            <div className='notsigned-up-yet'>
              <div className='notsigned-text'>Not signed up yet?</div>
              <div className='notsigned-innertext' >{signupModelDescription}</div>
              <a className='No-thanks signup-itsfree' onClick={()=>setIframeModule(true)} >Sign up</a>
            </div>
          </div>
        )
      }
    </>
  )
}
