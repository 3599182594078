import { useEffect, useState } from "react";
import PreStatsModel from "../modals/PreStatsModel";

export default function EmbededCase({
  setIsGuessModelOpen,
  setIsInfoModalOpen,
}) {
  const [isPreStatModelOpen, setIsPreStatModelOpen] = useState(false);

  const toggleModalState = () => {
    setIsPreStatModelOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="isEmbed" >
        <i
          onClick={toggleModalState}
          id="info-how-to-play"
          className="fa fa-question-circle-o"
          aria-hidden="true"
          style={{ color: "black", fontSize: "27px", cursor: "pointer" }}
        ></i>
      
      {isPreStatModelOpen && (
        <PreStatsModel
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsGuessModelOpen={setIsGuessModelOpen}
          isOpen={isPreStatModelOpen}
          handleClose={toggleModalState}
        />
      )}
      </div>
    </>
  );
}
