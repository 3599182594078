import { useEffect, useState } from "react";
import PreStatsModel from "../modals/PreStatsModel";
import { useSearchParams } from "react-router-dom";

export default function TopHead({ setIsGuessModelOpen, setIsInfoModalOpen, headerTitle ,isAddShow , deskDynamicClass }) {
  const [isPreStatModelOpen, setIsPreStatModelOpen] = useState(false);

  const toggleModalState = () => {
    setIsPreStatModelOpen(prevState => !prevState);
  };


  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    setIsDesktop(!isMobile);
  }, []);


    const [searchParams] = useSearchParams();
    const paramValue = searchParams.get('embed');


  return (
    <>
  {
  isAddShow && (paramValue === 'null' || paramValue == 'null' ||!paramValue ) &&
    isDesktop ? (<div className={`${deskDynamicClass} desk-ads`  }  > <div className="desk-ads-text" >ADVERTISEMENT</div></div>)
      :null
  }

   
      <div className="top-head-outline">
        <div className="top-head">
          <div className="top-brand-name">
           <img src="/headerlogo.png" /> News Word <span className="top-client-name" > {headerTitle}</span>
          </div>
          <div>
          <i
                onClick={toggleModalState}
                id="info-how-to-play"
                className="fa fa-question-circle-o"
                aria-hidden="true"
                style={{ color: 'black', fontSize:'27px', cursor:'pointer'}}
            ></i>
          </div>
        </div>
        {isPreStatModelOpen && (
        <PreStatsModel
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsGuessModelOpen={setIsGuessModelOpen}
          isOpen={isPreStatModelOpen}
          handleClose={toggleModalState}
        />
      )}
      </div>

      {/* {isPreStatModelOpen && (
        <PreStatsModel
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsGuessModelOpen={setIsGuessModelOpen}
          isOpen={isPreStatModelOpen}
          handleClose={toggleModalState}
        />
      )} */}
    </>
  );
}
