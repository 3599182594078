import { useEffect, useState } from "react";
import PreStatsModel from "../modals/PreStatsModel";
import { useSearchParams } from "react-router-dom";

export default function CustomHeader({ setIsGuessModelOpen, setIsInfoModalOpen ,isAddShow , deskDynamicClass , customHeader }) {
  const [isPreStatModelOpen, setIsPreStatModelOpen] = useState(false);

  const toggleModalState = () => {
    setIsPreStatModelOpen(prevState => !prevState);
  };


  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    setIsDesktop(!isMobile);
  }, []);


    const [searchParams] = useSearchParams();
    const paramValue = searchParams.get('embed');
    const baseURL = "https://newsword.ai/member-area/public/";
    const imgPath = `${baseURL}${customHeader.logo_image}`;


  return (
    <>
  {
  isAddShow && (paramValue === 'null' || paramValue == 'null' ||!paramValue ) &&
    isDesktop ? (<div className={`${deskDynamicClass} desk-ads`  }  > <div className="desk-ads-text" >ADVERTISEMENT</div></div>)
      :null
  }

   
      <div className="top-head-outline" style={{backgroundColor:customHeader.background_color , borderBottom:customHeader.border_bottom, maxHeight:'58px'}}>
        <div className="top-head" style={{maxHeight:'58px',alignItems:'center'}}>
          <div className="top-brand-name">
            {customHeader?.logo_image ? (
                <img src={imgPath} style={{height:customHeader.logo_image_height}}/>
              ) : null}
              
           <span className="top-client-name" style={{fontSize:customHeader?.font_size+`px`, fontWeight:customHeader?.font_wieght,color:customHeader?.text_color }} >
            {customHeader?.header_text} </span>
          </div>
          <div>
              <i
                onClick={toggleModalState}
                id="info-how-to-play"
                className="fa fa-question-circle-o"
                aria-hidden="true"
                style={{ color: customHeader?.icon_color, fontSize:'27px', cursor:'pointer'}}
            ></i>
          </div>
        </div>
        {isPreStatModelOpen && (
        <PreStatsModel
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsGuessModelOpen={setIsGuessModelOpen}
          isOpen={isPreStatModelOpen}
          handleClose={toggleModalState}
        />
      )}
      </div>
    </>
  );
}
